export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Hubs',
    route: 'hubs',
    icon: 'PackageIcon',
  },
  {
    title: 'Producers',
    route: 'producers',
    icon: 'ArchiveIcon',
  },
  {
    title: 'Products',
    route: 'products',
    icon: 'ListIcon',
  },
  {
    title: 'Orders',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Invoices',
    route: 'invoices',
    icon: 'FileTextIcon',
  },
  {
    title: 'Invoices Corrections',
    route: 'invoices-corrections',
    icon: 'FileMinusIcon',
  },
  {
    title: 'Export Data',
    route: 'export-data',
    icon: 'DownloadIcon',
  },
  {
    title: 'User Management',
    route: 'user-management',
    icon: 'UserIcon',
  },
]
